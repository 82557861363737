import React from 'react';

export function P(
  props: React.HTMLAttributes<HTMLParagraphElement>,
): JSX.Element {
  return <p {...props} />;
}

export function Div(props: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return <div {...props} />;
}

export function H1(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h1 {...props} />;
}

export function H2(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h2 {...props} />;
}

export function H3(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h3 {...props} />;
}

export function H4(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h4 {...props} />;
}

export function H5(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h5 {...props} />;
}

export function H6(
  props: React.HTMLAttributes<HTMLHeadingElement>,
): JSX.Element {
  return <h6 {...props} />;
}

export function Li(props: React.HTMLAttributes<HTMLLIElement>): JSX.Element {
  return <li {...props} />;
}

export function Input(
  props: React.InputHTMLAttributes<HTMLInputElement>,
): JSX.Element {
  return <input {...props} />;
}

export function Select(
  props: React.SelectHTMLAttributes<HTMLSelectElement>,
): JSX.Element {
  return <select {...props} />;
}

export function Span(
  props: React.HTMLAttributes<HTMLSpanElement>,
): JSX.Element {
  return <span {...props} />;
}
